@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";

.banner {
    background: none;
    display: flex;
    justify-content: center;
    height: auto;

    .box-contacts {
        display: flex;
        flex-direction: column;
        max-width: $maxContentWidth;
        width: 90%;

        .title-page{
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            margin-bottom: 0;
            margin-top: 20px;
            color: $secondaryColor;
        }
        p {
            margin-top: 0;
            font-size: 1.3rem;
            font-weight: 400;
            text-align: center;
            color: $thirdColor;
            margin-bottom: 30px;
        }
        .list-contacts {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                color: $secondaryColor;
                margin: 15px 0;
                font-size: 1.3rem;
                font-weight: 400;
                .icon-contact {
                    margin-right: 10px;
                }
            }
        }
    }
}

.faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        margin-top: 60px;
        margin-bottom: 10px;
        font-size: 2rem;
        color: $secondaryColor;
        width: 90%;
        text-align: center;
    }
    .item-faq {
        width: 90%;
        margin-top: 20px;

        h4 {
            padding: 15px 20px;
            font-weight: bold;
            font-size: 1.4rem;
            margin-bottom: 0;
            margin-top: 0;
            color: $lightColor;
            background-color: $primaryColor;
            border-top-left-radius: 40px;
            border-top-right-radius: 40px;
        }
        p {
            font-weight: 400;
            margin-top: 0;
            margin-bottom: 0;
            text-align: justify;
            font-size: 1.2rem;
            background-color: $lightColor;
            color: $thirdColor;
            padding: 15px 20px;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
        }
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    .banner {
        background-image: url("./../../assets/banner_contact.png");
        background-position: center;
        background-size: cover;
        align-items: center;
        height: 470px;

        .box-contacts {
            align-items: flex-end;
            .title-page, p, .list-contacts {
                margin-top: 0;
                text-align: left;
                width: 40%;
            }
        }
    }

    .faq {
        h3 {
            width: 70%;
        }
        .item-faq {
            width: 70%;
        }
    }

}

@media (min-width: 992px) {
    .banner {
        height: 500px;
    }

    .faq {
        h3 {
            width: 60%;
        }
        .item-faq {
            width: 60%;
        }
    }
}

@media (min-width: 1200px) {
    .banner {
        height: 620px;
    }
    .faq {
        h3 {
            width: 55%;
        }
        .item-faq {
            width: 55%;
        }
    }
}

@media (min-width: 1400px) {
}

@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";

.banner-about {
    background: none;
    display: flex;
    justify-content: center;
    height: auto;

    .box-about {
        display: flex;
        flex-direction: column;
        max-width: $maxContentWidth;
        width: 90%;

        .title-page{
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            margin-bottom: 0;
            margin-top: 20px;
            color: $secondaryColor;
        }
        p {
            margin-top: 0;
            font-size: 1.3rem;
            font-weight: 400;
            text-align: justify;
            color: $thirdColor;
            margin-bottom: 30px;
        }
    }
}

.box-company {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: $maxContentWidth;
    width: 90%;
    margin: 0 auto;

    .box-details-company {
        background-color: $lightColor;
        box-shadow: #ddd 0 0 10px;
        padding: 15px 15px;
        margin: 15px 0;
        width: 80%;
        height: 130px;
        border-bottom: 4px solid $fourthColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
            color: $secondaryColor;
            margin-top: 0;
            margin-bottom: 0px;
        }
        p {
            color: $thirdColor;
            text-align: center;
        }
    }
}

.box-map {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    max-width: $maxContentWidth;
    width: 90%;
    margin: 0 auto;

    h3 {
        font-size: 2.2rem;
        margin-bottom: 0;
        margin-top: 40px;
        text-align: center;
        color: $secondaryColor;
    }
    #gmap_canvas {
        margin-top: 20px;
        width: 80%;
        border-radius: 50px;
        box-shadow: 0 0 10px #d0d0d0;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    .banner-about {
        background-image: url("./../../assets/banner_about.png");
        background-position: center;
        background-size: cover;
        height: 470px;

        .box-about {
            align-items: flex-start;
            justify-content: center;
            margin: 0;

            .title-page {
                text-align: left;
            }
            .title-page, p {
                margin-top: 0;
                width: 50%;
            }
        }
    }


    .box-company {
        flex-direction: row;
        justify-content: space-between;

        .box-details-company {
            height: 160px;
            width: 30%;
        }
    }


    .box-map {
        #gmap_canvas {
            width: 100%;
        }
    }

}

@media (min-width: 992px) {
    .banner-about {
        height: 500px;
    }
    .box-company {
        flex-direction: row;
        justify-content: space-between;

        .box-details-company {
            height: 160px;
            width: 25%;
        }
    }
}

@media (min-width: 1200px) {
    .faq {
        h3 {
            width: 55%;
        }
        .item-faq {
            width: 55%;
        }
    }
}

@media (min-width: 1400px) {
    .banner-about {
        height: 650px;
    }
}

@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";
@import "./../../_styles/effects.scss";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .shadow-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        z-index: 0
    }

    .box-modal {
        position: relative;
        z-index: 1;
        width: 90%;
        max-width: $maxContentWidth;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: #000 0 0 10px;
        min-height: 200px;
        padding: 10px;

        .btn-close {
            background: none;
            border: 0;
            padding: 7px;
            float: right;
            font-size: 1.5rem;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    .modal {
        .shadow-modal {
            .box-modal {
                width: 50%;
            }
        }
    }
}

@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";

.banner-products {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;

    .img-banner{
        width: 100%
    }


    .box-products {
        display: flex;
        flex-direction: column;
        max-width: $maxContentWidth;
        width: 100%;

        .title-page{
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            margin-bottom: 0;
            margin-top: 40px;
            color: $secondaryColor;
        }
    }
}

.box-categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: $maxContentWidth;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 70px;

    .box-btns-abas{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 20px;
        .btn-aba {
            font-size: 1.2rem;
            padding: 10px 30px;
            height: 45px;
            border-radius: 0;
            border: 0;
            background-color: $lightColor;
            color: #ddd;
            border-bottom: 3px solid $fourthColor;
            margin: 0;
            font-weight: bold;
            cursor: pointer;
        }
        .btn-aba.active {
            color: $thirdColor;
        }
        .btn-aba-1 {
            margin-right: 2px;
        }
        .btn-aba-2 {
            margin-left: 2px;
        }
    }
    .list-products {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;

        .item-product {
            margin: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


.content-details-fruit {
    margin: 20px;
    h3 {
        font-size: 2rem;
        color: $thirdColor;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
    }
    div {
        color: $secondaryColor;

    }
    h4 {
        font-size: 1.3rem;
        color: $thirdColor;
        margin-bottom: 0;
    }
    .row-buttons  {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .btn-download {
            background-color: $primaryColor;
            color: $lightColor;
            border: none;
            margin: 0;
            font-weight: 600;
            font-size: 1rem;
            padding: 10px 20px;
            margin-top: 10px;
            text-align: center;
            text-decoration: none;
            cursor: pointer;
            margin-right: 20px;
        }
    }
}
@media (min-width: 576px) {
    .box-categories {
        .list-products {
            width: 95%;
        }
    }
}

@media (min-width: 768px) {



}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

@media (min-width: 1400px) {

}

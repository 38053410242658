@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";
@import "./../../_styles/effects.scss";

.footer {
    @extend .gradient;
    width: 100%;
    margin: 0;
    margin-top: 70px;
    align-items: center;
    flex-direction: column;
    display: flex;

    .box-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        max-width: $maxContentWidth;
        padding-bottom: 20px;
        padding-top: 10px;

        .info-footer {
            max-width: 360px;
            color: $lightColor;
            margin-bottom: 30px;

            p {
                margin: 0;
                font-weight: 500;
            }
            b {
                font-size: 1.2rem;
            }

            .title-footer {
                color: $lightColor;
                font-size: 1.4rem;
                font-weight: 600;
                margin-bottom: 5px;
            }

            .box-certified {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                img {
                    margin-right: 10px;
                    width: 70px;
                    height: 70px;
                }
            }

            .box-lists {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-direction: row;
                width: 360px;

                .list-links {
                    list-style: none;
                    padding-left: 5px;
                    margin: 0;

                    li {
                        padding: 0;
                        margin: 0;
                        a {
                            text-decoration: none;
                            color: $lightColor;
                        }
                        a:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .info-footer.iacc {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                margin-right: 20px;
            }
            .box-iacc {
                span {
                    font-size: 1.1rem;
                }

                .title-footer {
                    margin: 0;
                }
                .link-iacc {
                    background-color: $lightColor;
                    color: $secondaryColor;
                    border-radius: 40px;
                    padding: 8px 0;
                    width: 150px;
                    text-align: center;
                    font-weight: 800;
                    text-decoration: none;
                    font-size: 0.9rem;
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
    .subfooter {
        width: 100%;
        color: $lightColor;
        text-align: center;
        background-color: $primaryColor;
        font-size: 0.8rem;
        padding: 15px 0;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    .footer {
        .box-footer {
            width: 90%;
            margin-left: 5%;
            margin-right: 5%;
            flex-direction: row;

            .info-footer {
                .box-certified {

                    img {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }
    }
}

@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";
@import "./../../_styles/effects.scss";

.header-main {
    width: 100%;
    background-color: #fff;
    height: 100px;
    position: relative;
    z-index: 10;
    .submenu-header {
        background-color: $thirdColor;
        .content-subheader {
            max-width: $maxContentWidth;
            width: 90%;
            display: flex;
            margin: 0 auto;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .btn-flag {
                height: 35px;
                background: none;
                border: none;
                padding: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .selected, .btn-flag:hover {
                background-color: $primaryColor;
                cursor: pointer;
            }
        }
    }
    .content-header {
        display: flex;
        height: 65px;
        width: 90%;
        max-width: $maxContentWidth;
        margin: 0 auto;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;

        .menu-main {
            position: fixed;
            top: 0;
            left: 0;
            background-color: #fff;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title-menu {
                display: none;
            }
            .item-menu {
                text-align: center;
                padding: 15px 0;
                margin: 0 5%;
                color: $darkColor;
                background-color: $lightColor;
                font-size: 1.2rem;
                font-weight: 600;
                text-decoration: none;
                width: 90%;
            }
            .item-menu:hover {
                background-color: $thirdColor;
                color: $lightColor;
            }

            .btn-menu {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 90%;
                height: 40px;
                margin: 0 5%;
                font-size: 1rem;
                color: $closeColor;
                background-color: $lightColor;
                border: 1px solid $closeColor;
                cursor: pointer;
            }
            .btn-menu:hover {
                background-color: $closeColor;
                color: $lightColor;
            }
        }

        .menu-main.closed {
            position: inherit;
            width: auto;

            .title-menu {
                font-size: 0.8rem;
                margin: 0;
                display: block;
                color: $secondaryColor;
            }
            .item-menu {
                display: none;
            }
            .btn-menu {
                width: 60px;
                font-size: 2rem;
                margin: 0;
                border: none;
                color: $secondaryColor;
            }
            .btn-menu:hover {
                background-color: $secondaryColor;
                color: $lightColor;
            }
        }
    }
}

@media (min-width: 768px) {
    .header-main {
        .content-header {
            .menu-main,
            .menu-main.closed {
                position: inherit;
                flex-direction: row;
                display: flex;
                justify-content: flex-end;
                flex: 1;

                .title-menu,
                .btn-menu {
                    display: none;
                }

                .item-menu {
                    display: flex;
                    width: auto;
                    margin: 0;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px;
                    font-size: 1rem;
                }
            }
        }
    }
}

@media (min-width: 992px) {
}

@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";
@import "./../../_styles/effects.scss";

.features {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .item-feature {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        max-width: $maxContentWidth;
        margin-bottom: 30px;

        h2 {
            font-size: 2.2rem;
            color: $secondaryColor;
            text-align: center;
            margin-bottom: 10px;
        }

        p {
            margin-top: 0;
            text-align: center;
            font-size: 1.5rem;
            color: $thirdColor;
        }
    }
    .text-main-features {
        color: $secondaryColor;
        font-size: 1.8rem;
        text-align: center;
        margin: 50px auto;
        width: 80%;
        max-width: $maxContentWidth;
    }
}

@media (min-width: 768px) {
    .features {
        .item-feature {
            flex-direction: row;
            .img-feature {
                margin-right: 20px;
            }
            h2 {
                text-align: left;
            }

            p {
                text-align: justify;
            }
        }
        .item-feature.inverse {
            flex-direction: row-reverse;
            .img-feature {
                margin-left: 20px;
                margin-right: 0;
            }
        }
    }
}

@media (min-width: 992px) {
}

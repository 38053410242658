@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";
@import "./../../_styles/effects.scss";

.box-product {
    width: 160px;
    height: 220px;
    background-color: $lightColor;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: #f0f0f0 0 0 10px;
    margin: 10px 0;

    h4 {
        font-size: 1rem;
        color: $secondaryColor;
        margin: 0;
        margin-top: 10px;
        text-align: center;
    }
    .btn-more {
        background-color: $thirdColor;
        color: $lightColor;
        border: none;
        width: 80%;
        margin: 0 auto;
        font-weight: 600;
        font-size: 0.9rem;
        padding: 10px 0;
        margin-top: 10px;
        text-align: center;
        text-decoration: none;
        cursor: pointer;

    }
    .btn-more:hover {
        background-color: $secondaryColor;
    }
}

.box-product:hover {
    img {
        animation-name: animacao;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        height: 105px;
    }
}
@keyframes animacao {
    from {
        height: 100px;
    }
    to {
        height: 105px;
    }
 }

@media (min-width: 768px) {
    .box-product {
        width: 180px;
        height: 260px;
    }
}

@media (min-width: 992px) {
}

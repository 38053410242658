@import "./../../_styles/constants.scss";
@import "./../../_styles/color.scss";
@import "./../../_styles/effects.scss";

.seasonality {
    margin: 0;
    margin-bottom: 60px;

    .box-title-seasonality {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: $maxContentWidth;
        margin: 0 auto;
        width: 90%;

        h2 {
            font-size: 2.8rem;
            color: $secondaryColor;
            width: 100%;
            margin-bottom: 5px;
        }

        .dropdown-month {
            width: 100%;
            background-color: $lightColor;
            margin-bottom: 20px;

            .Dropdown-control {
                background-color: transparent;
                border: 0;
                font-size: 1.3rem;
                font-weight: 800;
                color: $darkColor;
            }
        }
    }

    .list-products-seasonality {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        max-width: $maxContentWidth;
        padding: 20px 0;

        .item-product {
            margin: 0 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media (min-width: 576px) {
    .seasonality {
        .list-products-seasonality {
            width: 95%;
        }
    }
}

@media (min-width: 768px) {
    .seasonality {
        .box-title-seasonality {
            flex-direction: row;
            justify-content: space-between;
            h2 {
                width: auto;
                margin: 5px 0;
            }
            .dropdown-month {
                width: auto;
                margin: 0;
            }
        }

        .list-products-seasonality {
            width: 90%;
        }
    }
}

@media (min-width: 992px) {
}

//@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import "./constants.scss";
@import "./color.scss";
@import "./fonts.scss";


* {
    font-family: "polly_roundedregular";
}
body,
html {
    margin: 0;
    padding: 0;
    font-size: 11px;
    background-color: #f9f9f9;
}
.page-content {
    position: relative;
    z-index: 2;
}
.seeds {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('../assets/background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}
.hidden {
    display: none !important;
}

.page {
    max-width: $maxContentWidth;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0px 5%;
    margin-top: 70px;

    .info-page {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .title-page {
            color: $secondaryColor;
            font-weight: 600;
            font-size: 2.3rem;
            margin: 0;
            width: 100%;
        }
        .desc-page {
            margin-top: 0;
            font-weight: 300;
            font-size: 1.2rem;
            color: $grayColor;
            width: 100%;
        }
    }
    .image-page {
        display: none;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    body,
    html {
        font-size: 14px;
    }
}

@media (min-width: 992px) {
    .page {
        align-items: flex-start;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        .info-page {
            width: 60%;
            .title-page {
                font-size: 2.5rem;
            }
        }
        .image-page {
            display: block;
        }
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}

@font-face {
    font-family: 'polly_roundedregular';
    src: url('../assets/fonts/pollyrounded-regular-webfont.eot');
    src: url('../assets/fonts/pollyrounded-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../assets/fonts/pollyrounded-regular-webfont.woff2') format('woff2'),
         url('../assets/fonts/pollyrounded-regular-webfont.woff') format('woff'),
         url('../assets/fonts/pollyrounded-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
